// src/components/Slider.js

import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./Slider.css";
import jettyimage from "../Assets/GettyImages.jpg";
import carryimage from "../Assets/boat-carry.jpg";
import blue from "../Assets/blue-wooden.jpg";
import houseboat from "../Assets/houseboat.jpg";
import allapey from "../Assets/houseboat-alappuzha.jpg";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const ImageSlider = () => {
  const navigate = useNavigate();
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  const images = [carryimage, jettyimage, blue, houseboat, allapey];

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [adults, setAdults] = useState(1);
  const [children, setChildren] = useState(0);
  const handlernavigation = () => {
    const bookingData = {
      startDate: startDate,
      endDate: endDate,
      adults: adults,
      children: children,
    };
    if (startDate && endDate) {
      navigate("/Booking", { state: bookingData });
    } else {
      toast.error("select the booking date");
      // alert("select the booking date");
    }
  };
  return (
    <div className="slider-container">
      <Slider {...settings}>
        {images.map((image, index) => (
          <div key={index}>
            <img
              src={image}
              alt={`Slide ${index + 1}`}
              className="slider-image"
            />
          </div>
        ))}
      </Slider>
      <div className="booking-form">
        <h3>Book Your Stay</h3>
        <div className="date-picker">
          <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            placeholderText="Check-in Date"
            className="date-input"
            required
          />
          <DatePicker
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            placeholderText="Check-out Date"
            className="date-input"
            required
          />
        </div>
        <div className="guest-selection">
          <div className="guest-item">
            <label>Adults</label>
            <select
              required
              value={adults}
              onChange={(e) => setAdults(e.target.value)}
            >
              {[...Array(10).keys()].map((num) => (
                <option key={num + 1} value={num + 1}>
                  {num + 1}
                </option>
              ))}
            </select>
          </div>
          <div className="guest-item">
            <label>Children</label>
            <select
              value={children}
              onChange={(e) => setChildren(e.target.value)}
            >
              {[...Array(10).keys()].map((num) => (
                <option key={num} value={num}>
                  {num}
                </option>
              ))}
            </select>
          </div>
        </div>
        <button onClick={() => handlernavigation()} className="book-button">
          Book Now
        </button>
      </div>
    </div>
  );
};

export default ImageSlider;
