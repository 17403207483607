// src/components/Gallery.js

import React from "react";
import "./Gallery.css";
import one from "../Assets/aquahomestay.jpeg";
import two from "../Assets/aqualillies.jpeg";
import three from "../Assets/bedhomestay.jpeg";
import four from "../Assets/front lakehouse.jpeg";
import five from "../Assets/homestay.jpeg";
import six from "../Assets/homestayaqua.jpeg";
import seven from "../Assets/lake.jpeg";
import eight from "../Assets/roombedhomestay.jpeg";

const Gallery = () => {
  const galleryImages = [one, two, three, four, five, six, seven, eight];

  return (
    <div className="gallery">
      <h2>Our Gallery</h2>
      <div className="gallery-grid">
        {galleryImages.map((image, index) => (
          <div key={index} className="gallery-item">
            <img src={image} alt={`Gallery ${index + 1}`} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Gallery;
