import React, { useState } from "react";
import "./Booking.css";
import NavigationBar from "./Navbar";
import Footer from "./Footer";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";

const VillaOption = ({
  index,
  guests,
  price,
  taxes,
  breakfastPrice,
  onGuestChange,
  isDisabled,
}) => {
  const guestCount = parseInt(guests.match(/\d+/g)[1]);

  return (
    <div className="villa-option">
      <div className="guests">{guests} Guests</div>
      <div className="pricing">
        <span className="discounted-price">₹{price.discounted}</span>
        <span className="taxes">+ ₹{taxes} taxes and charges</span>
      </div>
      <div className="breakfast">Breakfast: ₹{breakfastPrice} (optional)</div>
      <div className="select-villa">
        <select
          onChange={(e) =>
            onGuestChange(index, guestCount, parseInt(e.target.value))
          }
          defaultValue={0}
          disabled={isDisabled}
        >
          {[...Array(2).keys()].map((n) => (
            <option key={n} value={n}>
              {n}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

const Booking = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const bookingData = location.state;

  const [contactInfo, setContactInfo] = useState({
    name: "",
    email: "",
    phone: "",
    specialRequests: "",
  });
  const [selectedGuests, setSelectedGuests] = useState(0);
  const [selectedVillaIndex, setSelectedVillaIndex] = useState(null);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setContactInfo({ ...contactInfo, [name]: value });
  };
  const handleGuestChange = (index, guestCount, villaCount) => {
    if (villaCount > 0) {
      setSelectedVillaIndex(index); // Set the selected dropdown index
    } else {
      setSelectedVillaIndex(null); // Reset if none selected
    }

    const totalGuests = guestCount * villaCount;
    setSelectedGuests(totalGuests);
  };
  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const year = date.getFullYear();

    return `${year}/${month}/${day}`;
  }
  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (selectedVillaIndex === null) {
      toast.error("Please select one villa option before submitting.");
      return;
    }
    let RequestBody = {
      name: contactInfo.name,
      email: contactInfo.email,
      phone: contactInfo.phone,
      spe_request: contactInfo.specialRequests,
      checkin_date: formatDate(bookingData.startDate),
      checkout_date: formatDate(bookingData.endDate),
      adults: bookingData.adults,
      childs: bookingData.children,
      total_guests: selectedGuests,
    };
    console.log(RequestBody, "RequestBody");

    axios({
      method: "POST",
      url: "https://lunarsenterprises.com:6020/aqualillies/add/bookyourstay",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: RequestBody,
    })
      .then((response) => {
        if (response.data.result) {
          toast.success(response.data.message);
          navigate("/");
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((err) => {
        console.log(err, "error in booking form");
      });
  };

  const villaOptions = [
    {
      guests: "1 villa for 16",
      price: { original: 500, discounted: 375 },
      taxes: 45,
      breakfastPrice: 160,
    },
    {
      guests: "1 villa for 17",
      price: { original: 1000, discounted: 750 },
      taxes: 90,
      breakfastPrice: 160,
    },
    {
      guests: "1 villa for 18",
      price: { original: 1500, discounted: 1125 },
      taxes: 135,
      breakfastPrice: 160,
    },
    {
      guests: "1 villa for 19",
      price: { original: 2000, discounted: 1500 },
      taxes: 180,
      breakfastPrice: 160,
    },
    {
      guests: "1 villa for 20",
      price: { original: 2500, discounted: 1875 },
      taxes: 225,
      breakfastPrice: 160,
    },
  ];

  return (
    <>
      <NavigationBar />
      <div className="booking-container">
        <div className="booking-content">
          {/* Left Section: Accommodation Details */}
          <div className="villa-description">
            <h1>Accommodation</h1>
            <ul className="features">
              <li>Bedroom 1: 1 large double bed</li>
              <li>Bedroom 2: 1 large double bed and 2 futon beds</li>
              <li>
                Bedroom 3: 3 sofa beds, 1 large double bed, and 5 futon beds
              </li>
              <li>Bedroom 4: 1 extra-large double bed</li>
              <li>Bedroom 5: 1 double bed</li>
              <li>Free cot available on request</li>
            </ul>
            <p>
              Entire villa • 111 m² • Garden view • River view • Air
              conditioning
            </p>
          </div>

          {/* Right Section: Amenities */}
          <div className="amenities-section">
            <h2>Amenities</h2>
            <ul className="amenities-list">
              <li>Free toiletries</li>
              <li>Toilet</li>
              <li>Bath or shower</li>
              <li>Towels</li>
              <li>Linen</li>
              <li>Cleaning products</li>
              <li>Desk</li>
              <li>Seating Area</li>
              <li>Pants press</li>
              <li>Ironing facilities</li>
              <li>Shared bathroom</li>
              <li>Fan</li>
              <li>Extra long beds (2 metres)</li>
              <li>Towels/sheets (extra fee)</li>
              <li>Outdoor furniture</li>
              <li>Outdoor dining area</li>
              <li>Dining area</li>
              <li>Dining table</li>
              <li>Entire unit located on ground floor</li>
              <li>Clothes rack</li>
              <li>Fold-up bed</li>
              <li>Toilet paper</li>
              <li>Single-room air conditioning for guest accommodation</li>
              <li>Hand sanitiser</li>
            </ul>
          </div>
        </div>

        <div className="villa-header">
          <span>Number of Guests</span>
          <span>Today's Price</span>
          <span>Your Choices</span>
          <span>Select a Villa</span>
        </div>
        <div className="villa-options">
          {villaOptions.map((option, index) => (
            <VillaOption
              key={index}
              index={index}
              {...option}
              onGuestChange={handleGuestChange}
              isDisabled={
                selectedVillaIndex !== null && selectedVillaIndex !== index
              }
            />
          ))}
        </div>

        <p className="confirmation">● Confirmation is immediate</p>
        <p className="confirmation"> ✔ Free cancellation before one month</p>
        <div className="contact-information">
          <h2>Confirmation Form</h2>
          <form onSubmit={handleFormSubmit}>
            <div className="form-group">
              <label htmlFor="name">Name</label>
              <input
                type="text"
                id="name"
                name="name"
                value={contactInfo.name}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                value={contactInfo.email}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="phone">Phone</label>
              <input
                type="tel"
                id="phone"
                name="phone"
                value={contactInfo.phone}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="specialRequests">Special Requests</label>
              <textarea
                id="specialRequests"
                name="specialRequests"
                value={contactInfo.specialRequests}
                onChange={handleInputChange}
              />
            </div>
            <button type="submit" className="reserve-button">
              Submit Booking
            </button>
          </form>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Booking;
